import { Button } from "@chakra-ui/react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

interface BackButtonProps {
  backRoute?: string;
}

export const BackButton = ({ backRoute }: BackButtonProps) => {
  const history = useHistory();

  const handleBack = () =>
    backRoute ? history.push(backRoute) : history.goBack();

  return (
    <Button
      onClick={handleBack}
      size="small"
      layerStyle="lightOcean"
      _hover={{ layerStyle: "lightOcean" }}
      leftIcon={<ArrowBackIcon style={{ marginLeft: "6px" }} />}
    />
  );
};
