import { useState } from "react";

const HIDED_COLUMNS_KEY = "hidedColumnsKey";

export function useHidedColumns() {
  const [hidedColumns, setHidedColumns] = useState<string[]>(() => {
    const localStorageHidedColumns = localStorage.getItem(HIDED_COLUMNS_KEY);

    if (!localStorageHidedColumns) return [];

    const data = JSON.parse(localStorageHidedColumns);

    return data;
  });

  const onHideEnviromentCollumn = (label: string) => {
    setHidedColumns((prev) => {
      const updatedHidedColumns = [...prev, label];
      localStorage.setItem(
        HIDED_COLUMNS_KEY,
        JSON.stringify(updatedHidedColumns)
      );
      return updatedHidedColumns;
    });
  };

  const onUpdateHidedColumns = (data: string[]) => {
    setHidedColumns(data);
    localStorage.setItem(HIDED_COLUMNS_KEY, JSON.stringify(data));
  };

  const isColumnHided = (type: string | null) => {
    if (!type) return false;

    return hidedColumns.includes(type);
  };

  return {
    hidedColumns,
    onHideEnviromentCollumn,
    onUpdateHidedColumns,
    isColumnHided,
  };
}
