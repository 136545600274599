import RootStore from "../root-store";
import { wrapRoot } from "mobx-easy";
import OperationRuleService from "../../services/OperationRuleService";
import OperationRuleLocationService from "../../services/OperationRuleLocationService";
import OperationRuleLocationInsightService from "../../services/OperationRuleLocationInsightService";
import OceanWeatherIntelligenceService from "../../services/OceanWeatherIntelligenceService";
import AtmOceanService from "../../services/AtmOceanService";
import LogService from "../../services/LogService";
import AuthService from "../../services/AuthService";
import UserConfigService from "../../services/UserConfigService";
import OperationRuleAlertUserService from "../../services/OperationRuleAlertUserService";
import InsightAlertService from "../../services/InsightAlertService";
import PortofCallService from "../../services/PortofCallService";
import AnalyticsService from "../../services/AnalyticsService";
import NotificationService from "../../services/NotificationService";
import RegionService from "../../services/RegionService";
import SensorService from "../../services/SensorService";
import SomcService from "../../services/SomcService";
import AdminPanelConfigService from "../../services/AdminPanelConfigService";
import GoogleFirebase from "../../services/GoogleFirebase";
import TechnomarAuthService from "../../services/TechnomarAuthService";
import UnitMeasurementService from "../../services/UnitMeasurementService";
import ShareService from "../../services/ShareService";
import OccurrenceService from "../../services/OccurrenceService";
import FeedbackService from "../../services/FeedbackService";
import ManageStationService from "../../services/ManageStationService";
import AnalyticsReportingService from "../../services/AnalyticsReportingService";
import ProbabilisticForecastService from "../../services/ProbabilisticForecastService";
import MetOceanService from "services/MetOceanService";
import RadarService from "services/RadarService";
import ReportService from "services/ReportService";
export interface RootEnv {
  isDev: boolean;
  operationRuleService: OperationRuleService;
  operationRuleLocationService: OperationRuleLocationService;
  operationRuleLocationInsightService: OperationRuleLocationInsightService;
  operationRuleAlertUserService: OperationRuleAlertUserService;
  oceanWeatherIntelligenceService: OceanWeatherIntelligenceService;
  atmOceanService: AtmOceanService;
  logServices: LogService;
  authService: AuthService;
  userConfigService: UserConfigService;
  insightAlertService: InsightAlertService;
  portOfCallService: PortofCallService;
  analyticsService: AnalyticsService;
  notificationService: NotificationService;
  regionService: RegionService;
  sensorService: SensorService;
  somcService: SomcService;
  adminPanelConfigService: AdminPanelConfigService;
  googleFirebase: GoogleFirebase;
  technomarAuthService: TechnomarAuthService;
  unitMeasurementService: UnitMeasurementService;
  shareService: ShareService;
  occurrenceService: OccurrenceService;
  feedbackService: FeedbackService;
  manageStationService: ManageStationService;
  analyticsReportingService: AnalyticsReportingService;
  probabilisticForecastService: ProbabilisticForecastService;
  metOceanService: MetOceanService;
  radarService: RadarService;
  reportService: ReportService;
}

export const createStore = () => {
  const env = {
    isDev: process.env.NODE_ENV === "development",
    operationRuleService: new OperationRuleService(),
    operationRuleLocationService: new OperationRuleLocationService(),
    operationRuleLocationInsightService:
      new OperationRuleLocationInsightService(),
    operationRuleAlertUserService: new OperationRuleAlertUserService(),
    oceanWeatherIntelligenceService: new OceanWeatherIntelligenceService(),
    atmOceanService: new AtmOceanService(),
    logServices: new LogService(),
    authService: new AuthService(),
    userConfigService: new UserConfigService(),
    insightAlertService: new InsightAlertService(),
    portOfCallService: new PortofCallService(),
    analyticsService: new AnalyticsService(),
    notificationService: new NotificationService(),
    regionService: new RegionService(),
    sensorService: new SensorService(),
    somcService: new SomcService(),
    adminPanelConfigService: new AdminPanelConfigService(),
    googleFirebase: new GoogleFirebase(),
    technomarAuthService: new TechnomarAuthService(),
    unitMeasurementService: new UnitMeasurementService(),
    shareService: new ShareService(),
    occurrenceService: new OccurrenceService(),
    feedbackService: new FeedbackService(),
    manageStationService: new ManageStationService(),
    analyticsReportingService: new AnalyticsReportingService(),
    probabilisticForecastService: new ProbabilisticForecastService(),
    metOceanService: new MetOceanService(),
    radarService: new RadarService(),
    reportService: new ReportService(),
  };

  const rootStore = wrapRoot({
    RootStore: RootStore,
    env,
  });

  return rootStore as RootStore;
};
