import { EnvironmentalType } from "enum/EnvironmentalType";
import { useEffect } from "react";
import { useStores } from "stores/setup/use-store";

const useRadarData = () => {
  const {
    dataStores: { forecastChartPageStore, authStore },
  } = useStores();
  useEffect(() => {
    forecastChartPageStore.setEmptyRadarData(false);
    const companyId = localStorage.getItem("companyId") || "0";

    const showRadar = [
      81, 59, 79, 42, 29, 10, 46, 19, 66, 80, 51, 2, 85,
    ].includes(parseInt(companyId));

    if (showRadar) {
      const fetchRadarData = () => {
        if (
          forecastChartPageStore.tabStateActivty ===
          EnvironmentalType.PRECIPITATION
        ) {
          forecastChartPageStore.getRadarData();
        }
      };

      fetchRadarData();

      const intervalId = setInterval(fetchRadarData, 3 * 60 * 1000);

      return () => clearInterval(intervalId);
    }
  }, [forecastChartPageStore.tabStateActivty, authStore.companyId]);
};

export default useRadarData;
