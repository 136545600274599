import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { StoreState } from "enum/StoreState";

import { IStation } from "stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { TabularForecastTranslation } from "../types";
import { Select } from "antd";

interface SelectStationProps {
  selectedStation: string;
  onChange: (value: string) => void;
  stations: IStation[];
  state: string;
  translate: TabularForecastTranslation;
}

export function SelectStation({
  selectedStation,
  onChange,
  stations,
  state,
  translate,
}: SelectStationProps) {
  if (state === StoreState.PEDDING) {
    return (
      <Spinner
        thickness="4px"
        color="newBlue.500"
        size="sm"
        zIndex={99999999999}
      />
    );
  }

  if (state === StoreState.ERROR) {
    return <Text color="black">{translate("selectStationError")}</Text>;
  }

  return (
    <Box>
      <Text color="newBlue.500" as="label" htmlFor="select-station" mr={2}>
        {translate("selectStationLabel")}
      </Text>

      <Select
        id="select-station"
        showSearch
        optionFilterProp="children"
        onChange={onChange}
        value={selectedStation}
        style={{ width: "350px" }}
      >
        {stations.map((station) => {
          return (
            <option
              value={`${station.station_id}-${station.region_id}`}
              key={station.station_id}
            >
              {station.station_name} - {station.macro_region_name}
            </option>
          );
        })}
      </Select>
    </Box>
  );
}
