import api from "./api";

export interface IReportServicesParams {
  stationId: number;
  regionId: number;
  forecastIntervalMinutes: number;
  forecastDays?: number;
}

export interface ReportDataKeys {
  wind_dir: unknown;
  wind_vel: unknown;
  gust: unknown;
  wind_vel_80m: unknown;
  gust_80m: unknown;
  wind_vel_100m: unknown;
  gust_100m: unknown;
  precip: unknown;
  temp_air: unknown;
  slp: unknown;
  visibility: unknown;
  wave_height: unknown;
  wave_height_max: unknown;
  peak_period: unknown;
  mean_period: unknown;
  wave_dir: unknown;
  wave_height_sea: unknown;
  peak_period_sea: unknown;
  wave_dir_sea: unknown;
  wave_height_swell: unknown;
  peak_period_swell: unknown;
  wave_dir_swell: unknown;
  water_level: unknown;
  current_vel: unknown;
  current_dir: unknown;
}

export interface UnitMeasurement
  extends Partial<
    Record<keyof ReportDataKeys, { units: string; units_symbol: string }>
  > {}

export interface ReportForecastData
  extends Partial<Record<keyof ReportDataKeys, (number | null)[]>> {}

export interface ReportServiceData {
  tabularForecastData: {
    reportColumns: string[];
    reportForecastData: {
      dates: (string | null)[];
      data: ReportForecastData;
      unitMeasurement: UnitMeasurement;
      timezone: string;
    };
  };
}

export default class ReportService {
  async getForecastReportData(
    params: IReportServicesParams,
    controller: AbortController
  ): Promise<ReportServiceData> {
    const result = await api.get(`/v1/metocean-report/get-tabular-forecast`, {
      params,
      signal: controller.signal,
    });

    return result.data;
  }
}
