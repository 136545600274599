import { Center, Box, Text, Progress } from "@chakra-ui/react";
import { TabularForecastTranslation } from "../types";

interface ForecastTableLoadingProps {
  translate: TabularForecastTranslation;
}

export const ForecastTableLoading = ({
  translate,
}: ForecastTableLoadingProps) => {
  return (
    <Center flexDirection="column" gap={4} height="50vh">
      <Box textAlign={"center"}>
        <Text fontWeight={"bold"} color="newBlue.500">
          {translate("loadingForecastTableData")}
        </Text>
        <Text as="p" color="black">
          {translate("forecastTablePleaseWaitAMoment")}
        </Text>
      </Box>
      <Progress w="400px" size="xs" isIndeterminate />
    </Center>
  );
};
