import { useMemo } from "react";
import { useStores } from "stores/setup/use-store";
import CustomMarker from "../Components/CustomMarker";

const useCustomMarkers = () => {
  const {
    dataStores: { forecastChartPageStore },
  } = useStores();
  return useMemo(() => {
    return forecastChartPageStore.stations.map((station) => (
      <CustomMarker
        key={`${station.station_id}-${station.region_id}`}
        station={station}
      />
    ));
  }, [forecastChartPageStore.stations]);
};

export default useCustomMarkers;
