import { useMapEvents } from "react-leaflet";
import { useCallback } from "react";
import { useStores } from "stores/setup/use-store";

export const MapClickHandler = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      forecastChartMeteogramStore,
      regionStore,
    },
  } = useStores();
  useMapEvents({
    click: useCallback((e: { latlng: { lat: any; lng: any } }) => {
      const { lat, lng } = e.latlng;

      forecastChartMeteogramStore.setOpenMeteogram(true);
      forecastChartMeteogramStore.resetInsightMeteogram();

      forecastChartPageStore.setSelectedStation(0);
      forecastChartPageStore.setClickMapLatLng(e.latlng);
      forecastChartPageStore.setStationInfo();
      forecastChartPageStore.updateMacroRegionOrigin(
        { lat, lng },
        regionStore.macroRegions
      );
      forecastChartPageStore.setActionCallMapClick();
    }, []),
  });

  return <> </>;
};
