export enum EnvironmentalType {
  WAVE = "wave",
  WAVE_SEA = "wave_sea",
  WAVE_SWELL = "wave_swell",
  WAVE_SPECTRA = "wave_spectra",
  WATER_FLOW = "water_flow",
  WEATHER = "weather",
  WEATHER_TEMPERATURE = "weather_temperature",
  WATER_LEVEL_HARMONIC = "water_level_harmonic",
  VISIBILITY = "visibility",
  CURRENT = "current", // temporary strategy for using measured data
  PRECIPITATION = "precipitation",
}

export enum MeteogramVariables {
  WIND_VEL = "wind_vel",
  WIND_VEL_80M = "wind_vel_80m",
  WIND_VEL_100M = "wind_vel_100m",
  WIND_DIR = "wind_dir",
  WIND_DIR_80M = "wind_dir_80m",
  WIND_DIR_100M = "wind_dir_100m",
  GUST = "gust",
  GUST_80M = "gust_80m",
  GUST_100M = "gust_100m",
  WATER_LEVEL = "water_level",
  CURRENT_VEL = "current_vel",
  CURRENT_DIR = "current_dir",
  WAVE_HEIGHT = "wave_height",
  WAVE_HEIGHT_MAX = "wave_height_max",
  WAVE_POWER = "wave_power",
  WAVE_DIR = "wave_dir",
  MEAN_WAVE_DIR = "mean_dir",
  PEAK_PERIOD = "peak_period",
  MEAN_PERIOD = "mean_period",
  WAVE_HEIGHT_SEA = "wave_height_sea",
  WAVE_DIR_SEA = "wave_dir_sea",
  PEAK_PERIOD_SEA = "peak_period_sea",
  WAVE_HEIGHT_SWELL = "wave_height_swell",
  WAVE_DIR_SWELL = "wave_dir_swell",
  PEAK_PERIOD_SWELL = "peak_period_swell",
  PRECIP = "precip",
  PRECIP_MIN_20KM = "precip_min_20km",
  TEMP_AIR = "temp_air",
  DEW_POINT = "dew_point",
  REL_HUMIDITY = "rel_humidity",
  MSLP = "MSLP",
  VISIBILITY = "visibility",
  VISIBILITY_MINIMUM_20KM = "visibility_minimum_20km",
  VISIBILITY_MAXIMUM_20KM = "visibility_maximum_20km",
  VISIBILITY_PROBABILITY_2000M = "visibility_probability_2000m",
  VISIBILITY_PROBABILITY_1500M = "visibility_probability_1500m",
  VISIBILITY_PROBABILITY_1000M = "visibility_probability_1000m",
}

export enum DataType {
  FORECAST = "forecast",
  MEASURED = "measured",
}
