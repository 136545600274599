import { ChangeEvent, useState } from "react";
import { Tresholds } from "../types";

const THRESHOLDS_KEY = "thresholdsKey";

export function useThreshold() {
  const [thresholds, setThresholds] = useState<Tresholds>(() => {
    const localStorageThresholds = localStorage.getItem(THRESHOLDS_KEY);

    const parsedLocalStorageThresholds: Tresholds =
      !!localStorageThresholds && JSON.parse(localStorageThresholds);
    return parsedLocalStorageThresholds ?? {};
  });

  const handleThresholdsInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    if (parseFloat(e.target.value) < 0) {
      return;
    }

    setThresholds((prev) => {
      const updatedThresholds = {
        ...prev,
        [key]: {
          value: e.target.value,
          operator: prev[key]?.operator || ">",
        },
      };
      localStorage.setItem(THRESHOLDS_KEY, JSON.stringify(updatedThresholds));
      return updatedThresholds;
    });
  };

  const handleThresholdOperatorToggle = (key: string) => {
    setThresholds((prev) => {
      const updatedThresholds = {
        ...prev,
        [key]: {
          ...prev[key],
          operator: prev[key]?.operator === ">" ? "<" : ">",
        },
      };
      localStorage.setItem(THRESHOLDS_KEY, JSON.stringify(updatedThresholds));
      return updatedThresholds;
    });
  };

  return {
    thresholds,
    handleThresholdsInputChange,
    handleThresholdOperatorToggle,
  };
}
