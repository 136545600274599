import { useEffect, useCallback } from "react";
import { useMap } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import { t } from "i18next";
import { useStores } from "stores/setup/use-store";
import { customMarkerUserIconSelected } from "./customMarkerUserIconSelected";

export const SearchControl = () => {
  const map = useMap();
  const {
    dataStores: { forecastChartPageStore, regionStore },
  } = useStores();

  const handleSearchLocation = useCallback((e: any) => {
    const { x, y } = e.location;

    forecastChartPageStore.setSelectedStation(0);
    forecastChartPageStore.setClickMapLatLng({ lat: y, lng: x });
    forecastChartPageStore.setStationInfo();
    forecastChartPageStore.updateMacroRegionOrigin(
      { lat: y, lng: x },
      regionStore.macroRegions
    );
  }, []);

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    //@ts-ignore
    const searchControl = new GeoSearchControl({
      provider,
      marker: { icon: customMarkerUserIconSelected },
      searchLabel: t("searchAddress"),
      autoClose: true,
      retainZoomLevel: true,
    });

    // Customizando comportamento do submit
    searchControl.__proto__.onSubmit = function ({ query, data }: any) {
      const latLongRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
      const match = query.match(latLongRegex);

      if (match) {
        const [lat, lng] = query.split(",");
        map.flyTo({ lat, lng }, 15);
        forecastChartPageStore.setSelectedStation(0);
        forecastChartPageStore.setClickMapLatLng({ lat, lng });
        forecastChartPageStore.setStationInfo();
        forecastChartPageStore.updateMacroRegionOrigin(
          { lat, lng },
          regionStore.macroRegions
        );
      } else if (data) {
        this.showResult(data);
      }
    };

    map.on("geosearch/showlocation", handleSearchLocation);
    map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
      map.off("geosearch/showlocation", handleSearchLocation);
    };
  }, [map, handleSearchLocation]);

  useEffect(() => {
    const handleResizeTooltip = () => {
      const zoom = map.getZoom();

      forecastChartPageStore.setMapZoom(zoom);
    };

    map.on("zoom", handleResizeTooltip);

    return () => {
      map.off("zoom", handleResizeTooltip);
    };
  }, [map]);

  return <></>;
};
