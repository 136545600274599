import { Td, Tr } from "@chakra-ui/react";
import { Row, ThresholdItem, Tresholds } from "../types";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";

interface TableRowProps {
  row: Row[];
  index: number;
  thresholds: Tresholds;
  hasScrolled: boolean;
  verifyIfColumnHided: (type: string | null) => boolean;
  verifyColumnType: (
    key: string
  ) =>
    | EnvironmentalType.WAVE_SEA
    | EnvironmentalType.WAVE_SWELL
    | EnvironmentalType.WATER_FLOW
    | EnvironmentalType.WEATHER
    | EnvironmentalType.WAVE
    | null;
  getColorForValue: (value: number, threshold: ThresholdItem) => string;
}

export function TableRow({
  row,
  index,
  thresholds,
  getColorForValue,
  verifyIfColumnHided,
  verifyColumnType,
  hasScrolled,
}: TableRowProps) {
  return (
    <Tr key={index} bg={index % 2 === 0 ? "#c0d5e5" : "white"}>
      {row.map((cell, cellIndex) => {
        const columnType = verifyColumnType(cell.key);
        const threshold = thresholds[cell.key];

        const isHidedColumn = verifyIfColumnHided(columnType);
        if (isHidedColumn) {
          return false;
        }

        const colorForValue = getColorForValue(Number(cell.value), threshold);
        const isDate = cell.key === "dates";

        return (
          <Td
            key={cellIndex}
            color="#006699"
            textAlign="center"
            border="1px solid black"
            padding="5px"
            bg={colorForValue}
            position={isDate ? "sticky" : "relative"}
            left={isDate ? 0 : "auto"}
            zIndex={isDate ? 1 : "auto"}
            _after={
              isDate && hasScrolled
                ? {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "0.5px",
                    height: "100%",
                    background: "black",
                  }
                : {}
            }
          >
            {cell.value ?? "-"}
          </Td>
        );
      })}
    </Tr>
  );
}
