import { EnvironmentalType } from "enum/EnvironmentalType";
import { useEffect, useState } from "react";
import { useStores } from "stores/setup/use-store";

export function useSelectedStation() {
  const [selectedStation, setSelectedStation] = useState("");
  const {
    dataStores: { forecastChartPageStore },
  } = useStores();

  const onChangeSelectedStation = (station: string) => {
    const [stationId, regionId] = station.split("-");
    setSelectedStation(`${stationId}-${regionId}`);
  };

  useEffect(() => {
    (async () => {
      await forecastChartPageStore.getStationsForecastChartAPI(
        EnvironmentalType.WEATHER
      ); // EnvironmentalType ignored by Backend!
      setSelectedStation(
        `${forecastChartPageStore.selectedStation}-${forecastChartPageStore.regionId}`
      );
    })();
  }, [forecastChartPageStore]);

  return {
    selectedStation,
    onChangeSelectedStation,
  };
}
