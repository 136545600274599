import { useCallback } from "react";
import { LatLngExpression } from "leaflet";
import { LAST_SELECTED_STATION_KEY } from "../../../../constants";
import { useStores } from "stores/setup/use-store";

const useMapCenter = (): LatLngExpression => {
  const {
    dataStores: { forecastChartPageStore },
  } = useStores();

  const getMapCenter = useCallback((): LatLngExpression => {
    const lastStation = localStorage.getItem(LAST_SELECTED_STATION_KEY);

    if (
      forecastChartPageStore.mapCenterLatitude &&
      forecastChartPageStore.mapCenterLongitude
    ) {
      const parsedStation =
        forecastChartPageStore.parseStationFromStorage(lastStation);

      const lastSelectedStation =
        forecastChartPageStore.stations.find(
          (station) =>
            station.station_id === parsedStation?.station_id &&
            station.region_id === parsedStation.region_id
        ) ?? forecastChartPageStore.stations[0];

      const mapCenterLatitude =
        lastSelectedStation?.station_lat ??
        forecastChartPageStore.mapCenterLatitude;
      const mapCenterLongitude =
        lastSelectedStation?.station_lon ??
        forecastChartPageStore.mapCenterLongitude;

      return [mapCenterLatitude, mapCenterLongitude];
    }
    return [0, 0];
  }, [forecastChartPageStore]);

  return getMapCenter();
};

export default useMapCenter;
