import { EnvironmentalType } from "enum/EnvironmentalType";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  IReportServicesParams,
  ReportServiceData,
  UnitMeasurement,
} from "services/ReportService";
import {
  ComposedUnits,
  Units,
} from "stores/data/unitMeasurement/UnitMeasurementStore";
import { useStores } from "stores/setup/use-store";
import { Column, ColumnInput, Row, ThresholdItem } from "../types";

export function useForecastTableData(selectedStation: string) {
  const [columns, setColumns] = useState<Column[]>([]);
  const [rows, setRows] = useState<Row[][]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);

  const columnNames = columns.map((column) => column.type);

  const {
    dataStores: { forecastChartPageStore, unitMeasurementStore },
  } = useStores();

  const getColumns = useCallback(
    (data: ReportServiceData) => {
      const showThresholdsList = [
        "wind_dir",
        "wind_vel",
        "gust",
        "wind_vel_80m",
        "gust_80m",
        "wind_vel_100m",
        "gust_100m",
        "precip",
        "temp_air",
        "slp",
        "visibility",
        "wave_height",
        "wave_height_max",
        "peak_period",
        "mean_period",
        "wave_dir",
        "wave_height_sea",
        "peak_period_sea",
        "wave_dir_sea",
        "wave_height_swell",
        "peak_period_swell",
        "wave_dir_swell",
        "water_level",
        "current_vel",
        "current_dir",
      ];

      const columns: Column[] = [
        {
          type: "date",
          label: "",
          inputs: [
            {
              label: "Date/Time",
              key: "dates",
              showThresholds: false,
              unit: data.tabularForecastData.reportForecastData.timezone,
              unitSymbol: data.tabularForecastData.reportForecastData.timezone,
            },
          ],
        },
        ...data.tabularForecastData.reportColumns.map((column) => {
          if (column === "visibility") {
            return {
              type: "visibility",
              label: "visibility",
              inputs: [
                {
                  label: "",
                  key: "",
                  showThresholds: false,
                  unit: null,
                  unitSymbol: null,
                },
              ],
            };
          }

          const inputs: ColumnInput[] = Object.entries(
            data.tabularForecastData.reportForecastData.data
          )
            .map(([key, value]: [string, unknown]) => {
              const typedValue = value as (number | null)[];
              const type = getType(key);
              if (
                type === column &&
                typedValue.length > 0 &&
                !typedValue.every((v) => v === null)
              ) {
                const typedKey =
                  key as keyof typeof data.tabularForecastData.reportForecastData.unitMeasurement;
                const unit =
                  data.tabularForecastData.reportForecastData.unitMeasurement?.[
                    typedKey
                  ]?.units ?? null;

                const unitSymbol =
                  data.tabularForecastData.reportForecastData.unitMeasurement?.[
                    typedKey
                  ]?.units_symbol ?? null;

                const userUnit = unitMeasurementStore.unitMeasurementConfig[
                  key
                ] as Units;

                return {
                  label: key,
                  key,
                  unit: unitMeasurementStore.unitMeasurementConfig[key] || unit,
                  showThresholds: showThresholdsList.includes(key),
                  unitSymbol: userUnit ?? unitSymbol,
                } as ColumnInput;
              }

              return null;
            })
            .filter((input): input is ColumnInput => input !== null);

          return {
            type: column,
            label: column,
            inputs,
          };
        }),
      ];

      const filteredColumns = columns.filter(
        (column) => column.type !== "visibility" && column.inputs.length > 0
      );

      setColumns(filteredColumns);
    },
    [unitMeasurementStore.unitMeasurementConfig]
  );

  const getRows = useCallback(
    (data: ReportServiceData) => {
      const dataWithDates: { [key: string]: (number | string | null)[] } = {
        dates: data.tabularForecastData.reportForecastData.dates,
        ...data.tabularForecastData.reportForecastData.data,
      };

      const filteredKeys = Object.keys(dataWithDates).filter((key) =>
        dataWithDates[key].some(
          (value) => value !== null && value !== undefined
        )
      );

      const maxLength = Math.max(
        ...filteredKeys.map((key) => dataWithDates[key].length)
      );

      const rows = [];
      for (let i = 0; i < maxLength; i++) {
        const row = filteredKeys.map((key) => {
          const value = dataWithDates[key][i] as number;
          const unitFromData =
            data.tabularForecastData.reportForecastData.unitMeasurement[
              key as keyof UnitMeasurement
            ];

          const userUnit = unitMeasurementStore.unitMeasurementConfig[
            key
          ] as Units;

          const haveUnit = !!unitFromData?.units_symbol && !!userUnit;
          const isTheSameUnit = unitFromData?.units_symbol === userUnit;
          const isDegress = unitFromData?.units === "degrees";

          if (unitFromData && unitFromData.units_symbol === "KT") {
            unitFromData.units_symbol = "kt";
          }

          const formattedData =
            haveUnit && !isDegress && !isTheSameUnit && value
              ? unitMeasurementStore.convertUnit(
                  value,
                  unitFromData?.units_symbol as ComposedUnits,
                  userUnit
                )
              : value;

          const hasDecimalCase = formattedData
            ?.toString()
            ?.includes("." || ",");

          const fixedValue = !hasDecimalCase
            ? formattedData
            : formattedData?.toFixed(1);

          return (
            {
              value:
                key !== "dates" && formattedData ? fixedValue : formattedData,
              key,
            } ?? null
          );
        });
        rows.push(row);
      }
      setRows(rows);
    },
    [unitMeasurementStore]
  );

  const getColorForValue = (value: number, threshold: ThresholdItem) => {
    const parsedTresholdValue = parseFloat(threshold?.value || "0");

    if (!threshold?.value || !value) return "inherit";
    if (threshold.operator === ">") {
      if (value >= parsedTresholdValue) return "red.300";
      if (value < parsedTresholdValue && value >= parsedTresholdValue * 0.9)
        return "yellow.300";
      return "green.300";
    } else {
      if (value <= parsedTresholdValue) return "red.300";
      if (value > parsedTresholdValue && value <= parsedTresholdValue * 1.1)
        return "yellow.300";
      return "green.300";
    }
  };

  const getType = (key: string) => {
    if (
      key.startsWith("wind") ||
      key.startsWith("precip") ||
      key.startsWith("temp") ||
      key.startsWith("slp") ||
      key.startsWith("visibility") ||
      key.startsWith("gust")
    ) {
      return EnvironmentalType.WEATHER;
    }
    if (
      key.startsWith("wave_height") ||
      key.startsWith("peak_period") ||
      key.startsWith("mean_period") ||
      key.startsWith("wave_dir")
    ) {
      if (key.includes("_sea")) return EnvironmentalType.WAVE_SEA;
      if (key.includes("_swell")) return EnvironmentalType.WAVE_SWELL;
      return EnvironmentalType.WAVE;
    }
    if (key.startsWith("water") || key.startsWith("current")) {
      return EnvironmentalType.WATER_FLOW;
    }
    return null;
  };

  const handleGetForecastReportData = useCallback(
    async (data: IReportServicesParams) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      await forecastChartPageStore.getForecastReportData(data, abortController);
      if (forecastChartPageStore.forecastReportData) {
        getColumns(forecastChartPageStore.forecastReportData);
        getRows(forecastChartPageStore.forecastReportData);
      }
      abortControllerRef.current = null;
    },
    [forecastChartPageStore, getColumns, getRows]
  );

  useEffect(() => {
    const [stationId, regionId] = selectedStation.split("-");

    if (stationId && regionId) {
      handleGetForecastReportData({
        regionId: Number(regionId),
        stationId: Number(stationId),
        forecastIntervalMinutes: 60,
      });
    }
  }, [selectedStation, forecastChartPageStore, handleGetForecastReportData]);

  return {
    columns,
    columnNames,
    rows,
    getColorForValue,
    getType,
  };
}
