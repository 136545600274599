import { LatLngExpression } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

export interface FlyToParams {
  searchedLatLng: LatLngExpression | null;
  clearSearchedLatLng: () => void;
}

export const FlyTo = ({ searchedLatLng, clearSearchedLatLng }: FlyToParams) => {
  const map = useMap();

  useEffect(() => {
    if (searchedLatLng) {
      map.flyTo(searchedLatLng, 10);
      clearSearchedLatLng();
    }
  }, [searchedLatLng, map, clearSearchedLatLng]);

  return <></>;
};
