import { useCallback } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import {
  extractCoordinates,
  getCoordinatesWithinRadius,
} from "util/getCoordinatesWithinRadius/getCoordinatesWithinRadius";
import { roundToNearestHour } from "util/roundHours/roundHours";
import ForecastChartPageStore from "stores/data/forecastChart/forecastChartPage/forecastChartPageStore";
import ForecastChartInsightStore from "stores/data/forecastChart/forecastChartInsight/forecastChartInsightStore";
import ForecastChartMeteogramStore from "stores/data/forecastChart/forecastChartMeteogram/forecastChartMeteogramStore";
import RegionStore from "stores/data/region/RegionStore";

export interface ReloadMultiStationInsightProps {
  forecastChartPageStore: ForecastChartPageStore;
  forecastChartInsightStore: ForecastChartInsightStore;
  forecastChartMeteogramStore: ForecastChartMeteogramStore;
  regionStore: RegionStore;
}

const useReloadMultiStationInsight = ({
  forecastChartPageStore,
  forecastChartInsightStore,
  forecastChartMeteogramStore,
  regionStore,
}: ReloadMultiStationInsightProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });
  const toast = useToast();

  return useCallback(() => {
    if (
      !forecastChartPageStore.insightMode ||
      !forecastChartMeteogramStore.selectedInsight
    ) {
      return;
    }

    if (!forecastChartInsightStore.insightMultiStation) {
      return;
    }

    const now = DateTime.utc().toMillis();
    const hasOutDatedData =
      forecastChartInsightStore.insightMultiStation.stationsData.some(
        (s: { calculatedAt: number }) => now - s.calculatedAt > 15 * 60 * 1000
      );

    if (!hasOutDatedData) {
      return;
    }

    if (!toast.isActive("toastId")) {
      toast({
        id: "toastId",
        title: t("insightDataUpdate"),
        description: t("insightDataUpdateDescription"),
        status: "info",
        position: "top",
        isClosable: true,
        duration: null,
      });
    }

    forecastChartInsightStore.setInsightMultiStation(null);
    forecastChartInsightStore.setSelectedCoordinatesWithinRadius([]);
    forecastChartInsightStore.setTimelineDate(null);

    const { station_lat, station_lon } =
      forecastChartPageStore.getStationByIdAndRegionId(
        forecastChartPageStore.selectedStation,
        forecastChartPageStore.regionId
      ) || {};

    if (!station_lat || !station_lon) {
      return;
    }

    const stationCoordinates = {
      latitude: station_lat,
      longitude: station_lon,
    };

    const coordinatesList = getCoordinatesWithinRadius(
      stationCoordinates,
      extractCoordinates(forecastChartPageStore.stations),
      forecastChartInsightStore.radius
    );

    forecastChartInsightStore.setSelectedCoordinatesWithinRadius(
      coordinatesList
    );
    forecastChartMeteogramStore.resetInsightMeteogram();

    const roundedNow = roundToNearestHour(now);
    forecastChartInsightStore.setTimelineDate({
      start: roundedNow.toMillis(),
      end: roundedNow.plus({ hours: 24 }).toMillis(),
      timezoneOffset: regionStore.mainRegionTimezoneOffset!,
    });
    forecastChartInsightStore.setSelectedTimelineDate({
      start: roundedNow.toMillis(),
      end: roundedNow.plus({ hours: 3 }).toMillis(),
      timezoneOffset: regionStore.mainRegionTimezoneOffset!,
    });
  }, [
    forecastChartPageStore,
    forecastChartInsightStore,
    forecastChartMeteogramStore,
    regionStore,
    t,
    toast,
  ]);
};

export default useReloadMultiStationInsight;
