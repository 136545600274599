import React from "react";
import { Formik } from "formik";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Checkbox,
  Select,
  Divider,
  Textarea,
  ModalCloseButton,
  Flex,
  Tooltip,
  Icon,
  Box,
  useToast,
  Text,
} from "@chakra-ui/react";
import DualListRecipients from "./DualListRecipients";
import { IUserShare } from "../../../../stores/data/userConfig/userConfigModal";
import { IStationRegion } from "../../../../types/IStationRegion";
import { EnvironmentalType } from "../../../../enum/EnvironmentalType";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Loading from "../../../../components/Loading/Loading";
import { StoreState } from "../../../../enum/StoreState";
import ShareStore from "../../../../stores/data/share/ShareStore";
import { extractStationAndRegionIds } from "util/extractStationRegionIds/extractStationAndRegionIds";

export interface ForecastConfig {
  environmentalType: EnvironmentalType;
  regionId: string;
  stationId: string;
}

interface Station {
  id: string;
  name: string;
}

interface FormValues {
  period: "24" | "48" | "72" | "0";
  weather: boolean;
  wave: boolean;
  waterFlow: boolean;
  weatherLocation: string;
  waveLocation: string;
  waterFlowLocation: string;
  invite: boolean;
  emails: string;
  message: string;
  recipients: number[];
  externalRecipients: string;
  sendMeEmail: boolean;
}

interface ShareFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  users: IUserShare[];
  stations: IStationRegion[];
  storeState: string;
  shareStore: ShareStore;
}

export const ShareFormModal: React.FC<ShareFormModalProps> = ({
  isOpen,
  onClose,
  users,
  stations,
  storeState,
  shareStore,
}) => {
  const toast = useToast();
  const { t } = useTranslation("translation", {
    keyPrefix: "share",
  });

  const initialValues: FormValues = {
    period: "24",
    weather: true,
    wave: false,
    waterFlow: false,
    weatherLocation: "",
    waveLocation: "",
    waterFlowLocation: "",
    invite: true,
    emails: "",
    message: "",
    recipients: [],
    externalRecipients: "",
    sendMeEmail: true,
  };

  const buildForecastConfig = (
    environmentalType: EnvironmentalType,
    location: string
  ): ForecastConfig => {
    const [stationId, regionId] = extractStationAndRegionIds(location);

    return {
      environmentalType,
      regionId,
      stationId,
    };
  };

  const getForecastConfig = (values: FormValues): ForecastConfig[] => {
    const {
      weather,
      weatherLocation,
      wave,
      waveLocation,
      waterFlow,
      waterFlowLocation,
    } = values;

    const forecastConfig: ForecastConfig[] = [];

    if (!weather && !wave && !waterFlow) {
      toast({
        title: t("selectAtLeastOneVariable"),
        status: "error",
        position: "top",
        isClosable: true,
      });
      return forecastConfig;
    }

    const validateLocation = (location: string) => {
      if (!location) {
        toast({
          title: t("selectAtLeastOneStation"),
          status: "error",
          position: "top",
          isClosable: true,
        });
        return false;
      }
      return true;
    };

    if (weather && validateLocation(weatherLocation)) {
      forecastConfig.push(
        buildForecastConfig(EnvironmentalType.WEATHER, weatherLocation)
      );
    }

    if (wave && validateLocation(waveLocation)) {
      forecastConfig.push(
        buildForecastConfig(EnvironmentalType.WAVE, waveLocation)
      );
    }

    if (waterFlow && validateLocation(waterFlowLocation)) {
      forecastConfig.push(
        buildForecastConfig(EnvironmentalType.WATER_FLOW, waterFlowLocation)
      );
    }

    return forecastConfig;
  };

  const emailValidation = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSendData = async (values: FormValues) => {
    const {
      period,
      recipients,
      externalRecipients,
      sendMeEmail,
      message,
      invite,
    } = values;

    const forecastConfig = getForecastConfig(values);

    if (!forecastConfig.length) {
      return;
    }

    const externalRecipientsList =
      externalRecipients.length > 0 ? externalRecipients.trim().split(",") : [];

    const invalidEmails =
      externalRecipientsList &&
      externalRecipientsList.length > 0 &&
      externalRecipientsList
        .map((email) => (!emailValidation(email) ? email : null))
        .filter((email) => email !== null);

    if (invalidEmails && invalidEmails.length > 0) {
      toast({
        title: t("emailsBelowAreNotValid"),
        description: invalidEmails,
        status: "error",
        position: "top",
        isClosable: true,
      });
      return;
    }

    if (
      externalRecipientsList.length === 0 &&
      recipients.length === 0 &&
      !sendMeEmail
    ) {
      toast({
        title: t("youMustSelectAtLeast"),
        status: "error",
        position: "top",
        isClosable: true,
      });
      return;
    }

    const recipientsIds = recipients.map((recipient: any) => recipient.id);

    const periodInMilliseconds =
      period === "0" ? undefined : parseInt(period) * 60 * 60 * 1000;

    const formValue = {
      period: periodInMilliseconds,
      forecastConfig: forecastConfig,
      recipientsUserIds: recipientsIds,
      externalRecipientsEmails: externalRecipientsList,
      inviteExternalRecipients: invite,
      includesSender: sendMeEmail,
      message,
    };

    await shareStore
      .shareForecastAPI(formValue)
      .then(() => {
        toast({
          title: t("successEmailHasSend"),
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          const value = error.response.data.message;
          toast({
            title: getErrorMessage(value),
            status: "error",
            position: "bottom-right",
            isClosable: true,
          });
        } else {
          toast({
            title: t("errorSendingEmail"),
            status: "error",
            position: "bottom-right",
            isClosable: true,
          });
        }
      });
  };

  const findStationName = (
    stationRegionId: string,
    stations: Station[]
  ): string => {
    const station = stations.find((s) => s.id === stationRegionId);
    return station?.name ?? "Unknown Station";
  };

  const getErrorMessage = (value: string): string => {
    let response: ForecastConfig;
    try {
      response = JSON.parse(value);
    } catch (error) {
      throw new Error("Input must be a valid JSON string");
    }
    const environmentalType = response.environmentalType as string;

    const stationRegionId = `${response.stationId}-${response.regionId}`;
    const stationName = findStationName(stationRegionId, stations);
    const environmentalTypeTranslated = t(environmentalType);

    return t("thereIsnotDataInThisStation", {
      environmentalTypeTranslated,
      stationName,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent bg={"#f0f1f2"}>
        <ModalHeader color="newBlue.500">{t("shareForecast")}</ModalHeader>
        <ModalCloseButton color={"black"} />

        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSendData(values);
          }}
        >
          {({
            values,
            handleChange,
            getFieldProps,
            submitForm,
            setFieldValue,
          }) => (
            <>
              <ModalBody
                pb={6}
                px={20}
                maxH="72.5vh"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    borderRadius: "8px",
                    backgroundColor: `rgba(40, 103, 149, 0.3)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "8px",
                    backgroundColor: `rgba(40, 103, 149, 0.6)`,
                  },
                }}
              >
                {storeState === StoreState.PEDDING && (
                  <Loading styleSpinner={{ marginLeft: 0 }} />
                )}
                <Text mb="1rem" color="gray.500" fontSize={"md"}>
                  {t("shareReportByEmail")}
                </Text>
                <FormControl as="fieldset" color="newBlue.500" isRequired>
                  <FormLabel>{t("period")}</FormLabel>
                  <RadioGroup {...getFieldProps("period")}>
                    <Stack spacing={2} direction="row">
                      <Radio
                        value={"24"}
                        defaultChecked={values.period === "24"}
                        onChange={handleChange}
                        borderColor="gray.400"
                      >
                        24h
                      </Radio>
                      <Radio
                        value={"48"}
                        isChecked={values.period === "48"}
                        onChange={handleChange}
                        borderColor="gray.400"
                      >
                        48h
                      </Radio>
                      <Radio
                        value={"72"}
                        isChecked={values.period === "72"}
                        onChange={handleChange}
                        borderColor="gray.400"
                      >
                        72h
                      </Radio>
                      <Radio
                        value={"0"}
                        isChecked={values.period === "0"}
                        onChange={handleChange}
                        borderColor="gray.400"
                      >
                        {t("all")}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <FormControl isRequired>
                  <Flex>
                    <FormLabel mt={4} color="newBlue.500">
                      {t("environmentalVariables")}
                    </FormLabel>
                    <Tooltip
                      label={t("selectAtLeastOneVariable")}
                      fontSize="sm"
                      hasArrow
                    >
                      <Icon
                        color={"gray.500"}
                        as={InfoOutlinedIcon}
                        pt={1}
                        mt={"14px"}
                      />
                    </Tooltip>
                  </Flex>

                  <Flex>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      color="newBlue.500"
                    >
                      <Checkbox
                        {...getFieldProps("weather")}
                        defaultChecked={
                          stations.length > 0 ? initialValues.weather : false
                        }
                        mr={3}
                        borderColor="gray.400"
                        isDisabled={!stations.length}
                      />
                      <FormLabel mt={2} cursor="pointer">
                        {t("weather")}
                      </FormLabel>
                    </FormControl>

                    <FormControl
                      display="flex"
                      alignItems="center"
                      color="newBlue.500"
                    >
                      <Select
                        id="weatherLocation"
                        placeholder={t("selectStation") as string}
                        ml={3}
                        {...getFieldProps("weatherLocation")}
                        disabled={!values.weather || !stations.length}
                        bg="white"
                      >
                        {stations?.map((value: IStationRegion) => (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                </FormControl>

                <Flex>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    color="newBlue.500"
                  >
                    <Checkbox
                      {...getFieldProps("wave")}
                      defaultChecked={
                        stations.length > 0 ? initialValues.wave : false
                      }
                      mr={3}
                      borderColor="gray.400"
                      isDisabled={!stations.length}
                    />
                    <FormLabel mt={2} cursor="pointer">
                      {t("wave")}
                    </FormLabel>
                  </FormControl>

                  <FormControl
                    display="flex"
                    alignItems="center"
                    color="newBlue.500"
                  >
                    <Select
                      id="waveLocation"
                      placeholder={t("selectStation") as string}
                      ml={3}
                      {...getFieldProps("waveLocation")}
                      disabled={!values.wave || !stations.length}
                      bg="white"
                      mt={2}
                    >
                      {stations?.map((value: IStationRegion) => (
                        <option value={value.id} key={value.id}>
                          {value.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>

                <Flex>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    color="newBlue.500"
                  >
                    <Checkbox
                      {...getFieldProps("waterFlow")}
                      defaultChecked={
                        stations.length > 0 ? initialValues.waterFlow : false
                      }
                      mr={3}
                      borderColor="gray.400"
                      isDisabled={!stations.length}
                    />
                    <FormLabel mt={2} cursor="pointer">
                      {t("waterFlow")}
                    </FormLabel>
                  </FormControl>

                  <FormControl
                    display="flex"
                    alignItems="center"
                    color="newBlue.500"
                  >
                    <Select
                      id="waterFlowLocation"
                      placeholder={t("selectStation") as string}
                      ml={3}
                      {...getFieldProps("waterFlowLocation")}
                      disabled={!values.waterFlow || !stations.length}
                      bg="white"
                      mt={2}
                    >
                      {stations?.map((value: IStationRegion) => (
                        <option value={value.id} key={value.id}>
                          {value.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>

                <Flex justifyContent={"space-between"} mt={2}>
                  <Box>
                    <FormControl>
                      <FormLabel color="newBlue.500">
                        {t("recipients")}
                      </FormLabel>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl display="flex">
                      <Checkbox
                        {...getFieldProps("sendMeEmail")}
                        defaultChecked={initialValues.invite}
                        mr={3}
                        borderColor="gray.400"
                      />
                      <FormLabel mt={2} color="newBlue.500">
                        {t("sendMeThisEmail")}
                      </FormLabel>
                    </FormControl>
                  </Box>
                </Flex>

                <FormControl
                  display="flex"
                  alignItems="center"
                  color="newBlue.500"
                >
                  <DualListRecipients
                    key={`id-${users[0]?.id}`}
                    users={users}
                    setFieldValue={setFieldValue}
                  />
                </FormControl>

                <Divider />

                <Flex justifyContent={"space-between"} mt={2}>
                  <Flex>
                    <FormControl mt={2} color="newBlue.500">
                      <FormLabel color="newBlue.500">
                        {t("externalRecipients")}
                      </FormLabel>
                    </FormControl>
                    <Tooltip
                      label={t("inThisFieldItsPossibleShareByEmail")}
                      fontSize="sm"
                      hasArrow
                    >
                      <Icon
                        color={"gray.500"}
                        as={InfoOutlinedIcon}
                        pt={1}
                        mt={"6px"}
                      />
                    </Tooltip>
                  </Flex>
                  <Flex>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      color="newBlue.500"
                    >
                      <Checkbox
                        {...getFieldProps("invite")}
                        defaultChecked={initialValues.invite}
                        mr={3}
                        borderColor="gray.400"
                      />
                      <FormLabel mt={2} color="newBlue.500">
                        {t("inviteToI4cast")}
                      </FormLabel>
                    </FormControl>
                    <Tooltip
                      label={t("forGuestUsersMustUseAnEmailDomain")}
                      fontSize="sm"
                      hasArrow
                    >
                      <Icon
                        color={"gray.500"}
                        as={InfoOutlinedIcon}
                        pt={1}
                        mt={"6px"}
                      />
                    </Tooltip>
                  </Flex>
                </Flex>

                <FormControl
                  display="flex"
                  alignItems="center"
                  color="newBlue.500"
                >
                  <Textarea
                    placeholder={t("separateEmailsWithComma") as string}
                    maxLength={100}
                    style={{
                      backgroundColor: "white",
                      borderColor: "white",
                      color: "rgba(30, 82, 151, 0.8)",
                      fontSize: 14,
                      fontWeight: "normal",
                    }}
                    borderRadius={8}
                    size="sm"
                    _focus={{
                      border: "2px",
                      borderColor: "newBlue.500",
                      boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
                    }}
                    borderColor="gray.300"
                    {...getFieldProps("externalRecipients")}
                  />
                </FormControl>

                <FormLabel mt={4} color="newBlue.500">
                  {t("message")}
                </FormLabel>
                <FormControl
                  display="flex"
                  alignItems="center"
                  color="newBlue.500"
                >
                  <Textarea
                    maxLength={100}
                    style={{
                      backgroundColor: "white",
                      borderColor: "white",
                      color: "rgba(30, 82, 151, 0.8)",
                      fontSize: 14,
                      fontWeight: "normal",
                    }}
                    borderRadius={8}
                    size="sm"
                    _focus={{
                      border: "2px",
                      borderColor: "newBlue.500",
                      boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
                    }}
                    borderColor="gray.300"
                    {...getFieldProps("message")}
                    placeholder={t("helloIWouldLikeToShare") as string}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  color="newBlue.500"
                  onClick={onClose}
                  mr={3}
                >
                  {t("cancel")}
                </Button>
                <Button
                  layerStyle="ocean"
                  _hover={{ layerStyle: "ocean" }}
                  onClick={submitForm}
                  type="submit"
                >
                  {t("submt")}
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
