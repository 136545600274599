import { useState, useEffect } from "react";

export function useUserHasScrolled(id: string) {
  const [hasScrolled, setHasScrolled] = useState(false);
  const container = document.getElementById(id);

  useEffect(() => {
    const handleScroll = () => {
      if (!container) return;

      setHasScrolled(container.scrollLeft > 0);
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [id, container]);

  return { hasScrolled };
}
