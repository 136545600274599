import { useEffect, useState } from "react";
import { useStores } from "stores/setup/use-store";
import useFetchInsightMultiStation from "../../../hooks/useFetchInsightMultiStation";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import useReloadMultiStationInsight from "./hooks/useReloadMultiStationInsight";
import useCustomMarkers from "./hooks/useCustomMarkers";
import useRadarData from "./hooks/useRadarData";
import useMapCenter from "./hooks/useMapCenter";
import { observer } from "mobx-react-lite";
import ForecastChartPanelMap from "./ForecastChartPanelMap";
import { customMarkerUserIconSelected } from "./util/customMarkerUserIconSelected";

const ForecastChartPanelMapContainer = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      forecastChartInsightStore,
      forecastChartMeteogramStore,
      regionStore,
    },
  } = useStores();

  useFetchInsightMultiStation(
    forecastChartInsightStore,
    forecastChartPageStore,
    forecastChartMeteogramStore.selectedInsight?.id
  );

  const customMarker = useCustomMarkers();

  useRadarData();

  const center = useMapCenter();

  const iconSelected = customMarkerUserIconSelected;

  const reloadMultiStationInsight = useReloadMultiStationInsight({
    forecastChartPageStore,
    forecastChartInsightStore,
    forecastChartMeteogramStore,
    regionStore,
  });

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });

  const toast = useToast();
  const toastRadarId = "toastRadarId";

  useEffect(() => {
    const intervalId = setInterval(reloadMultiStationInsight, 10 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!forecastChartPageStore.emptyRadarData) return;

    if (!toast.isActive(toastRadarId)) {
      toast({
        id: toastRadarId,
        title: t("noRadarDataAvailable"),
        status: "info",
        position: "top",
        isClosable: true,
        duration: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.emptyRadarData]);

  return ForecastChartPanelMap({
    center,
    zoom: forecastChartPageStore.mapZoom,
    customMarker,
    tabStateActivty: forecastChartPageStore.tabStateActivty,
    radarData: forecastChartPageStore.radarData,
    loadingInsightMultiStation:
      forecastChartInsightStore.loadingInsightMultiStation,
    clickMapLatLng: forecastChartPageStore.clickMapLatLng,
    selectedStation: forecastChartPageStore.selectedStation,
    iconSelected,
    insightMode: forecastChartPageStore.insightMode,
    insightMultiStation: forecastChartInsightStore.insightMultiStation,
    visualizationType: forecastChartMeteogramStore.visualizationType,
    regionId: forecastChartPageStore.regionId,
    sensorMarkerData: forecastChartPageStore.sensorMarkerData,
    mapCenterLatitude: forecastChartPageStore.mapCenterLatitude,
    searchedLatLng: forecastChartPageStore.searchedLatLng,
    clearSearchedLatLng: () => forecastChartPageStore.setSearchedLatLng(null),
  });
};

export default observer(ForecastChartPanelMapContainer);
