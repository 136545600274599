import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Switch,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { EnviromentTranslation, TabularForecastTranslation } from "../types";

interface ManageTabularForecastColumnsModalProps {
  isOpen: boolean;
  onClose: () => void;
  columns: (string | null)[];
  hidedColumns: string[];
  translate: EnviromentTranslation;
  translateForecast: TabularForecastTranslation;
  onUpdateHidedColumns: (data: string[]) => void;
}

interface FormItemData {
  [key: string]: boolean;
}

export function ManageTabularForecastColumnsModal({
  isOpen,
  onClose,
  columns,
  hidedColumns,
  translate,
  translateForecast,
  onUpdateHidedColumns,
}: ManageTabularForecastColumnsModalProps) {
  const initialData = columns.reduce((acc: FormItemData, column) => {
    if (column && column !== "date") {
      acc[column] = !hidedColumns.includes(column);
    }

    return acc;
  }, {});

  const handleSubmit = (values: FormItemData) => {
    const data = Object.entries(values).map(([key, value]) => {
      if (!value) return key;

      return null;
    });

    onUpdateHidedColumns(
      data.filter((item) => item && item !== "date") as string[]
    );
    onClose();
  };

  const isSubmitDisabled = (values: FormItemData) => {
    return Object.values(values).every((value) => !value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <ModalContent>
            <ModalHeader color="#006699">
              <h1>{translateForecast("manageColumnTitle")}</h1>
            </ModalHeader>
            <ModalCloseButton color="#006699" />

            <ModalBody color="black">
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                {columns.map((column) => {
                  if (!column || column === "date") {
                    return false;
                  }
                  return (
                    <GridItem key={column}>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel htmlFor="email-alerts" mb="0">
                          {translate(column)}
                        </FormLabel>
                        <Switch
                          id={column}
                          isChecked={values[column]}
                          onChange={(e) =>
                            setFieldValue(column, e.target.checked)
                          }
                        />
                      </FormControl>
                    </GridItem>
                  );
                })}
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                colorScheme="blue"
                mr={3}
                onClick={onClose}
              >
                {translateForecast("manageColumnsModalCancel")}
              </Button>
              <Button
                key="submit"
                type="submit"
                colorScheme="blue"
                isDisabled={isSubmitDisabled(values)}
                onClick={() => handleSubmit(values)}
              >
                {translateForecast("manageColumnsModalSave")}
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  );
}
