import { EyeInvisibleOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import MoreVert from "@material-ui/icons/MoreVert";
import { ChangeEvent } from "react";
import {
  Column,
  ColumnInput,
  EnviromentTranslation,
  TabularForecastTranslation,
  Tresholds,
} from "../types";

interface TableHeaderProps {
  columns: Column[];
  translate: TabularForecastTranslation;
  environmentTranslation: EnviromentTranslation;
  isHideColumnDisabled?: boolean;
  tresholds: Tresholds;
  verifyIfColumnIsHided: (type: string | null) => boolean;
  hasScrolled: boolean;
  onHideColumn: (type: string) => void;
  onOpenManageColumnsModal: () => void;
  onToggleOperator: (key: string) => void;
  onTresholdInputChange: (
    e: ChangeEvent<HTMLInputElement>,
    key: string
  ) => void;
}

const dateColBorderStyle = {
  content: '""',
  position: "absolute",
  top: 0,
  right: 0,
  width: "0.5px",
  height: "100%",
  background: "black",
};

export function TableHeader({
  columns,
  tresholds,
  hasScrolled,
  verifyIfColumnIsHided,
  translate,
  environmentTranslation,
  isHideColumnDisabled,
  onHideColumn,
  onOpenManageColumnsModal,
  onToggleOperator,
  onTresholdInputChange,
}: TableHeaderProps) {
  const getInputLabel = (input: ColumnInput) => {
    if (!input.label) return "";

    if (input.key === "dates") return translate("dates");

    return `${environmentTranslation(input.label)}`;
  };

  return (
    <Thead bg="#006699" position="sticky" top={0} zIndex={10}>
      <Tr>
        {columns.map((col, colIndex) => {
          const isDateCol = col.type === "date";
          return (
            !verifyIfColumnIsHided(col.type) && (
              <Th
                key={colIndex}
                colSpan={col.inputs.length || 1}
                color="white"
                textAlign="center"
                position={isDateCol ? "sticky" : "relative"}
                left={isDateCol ? 0 : "auto"}
                zIndex={isDateCol ? 100 : "10"}
                bg="#006699"
                border="1px solid black"
                _after={isDateCol && hasScrolled ? dateColBorderStyle : {}}
              >
                <Flex
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
                  padding="4px 0"
                  position={isDateCol ? "sticky" : "relative"}
                  left={isDateCol ? 0 : "auto"}
                  zIndex={isDateCol ? 2 : "auto"}
                >
                  <span style={{ flex: 1 }}>
                    {col.label ? environmentTranslation(col.label) : ""}
                  </span>
                  {col.type && !isDateCol && (
                    <Menu>
                      <Tooltip label="Menu" hasArrow>
                        <MenuButton
                          as={Button}
                          variant="outline"
                          onFocus={(e) => e.preventDefault()}
                          border={0}
                          padding={0}
                          size="sm"
                          _active={{
                            bg: "transparent",
                            filter: "brightness(0.8)",
                          }}
                          _focus={{ bg: "transparent" }}
                          _hover={{
                            bg: "transparent",
                            filter: "brightness(0.8)",
                          }}
                        >
                          <MoreVert />
                        </MenuButton>
                      </Tooltip>{" "}
                      <MenuList>
                        <MenuItem
                          color="black"
                          display="flex"
                          alignItems="center"
                          gap={2}
                          isDisabled={isHideColumnDisabled}
                          onClick={() => {
                            col.type && onHideColumn(col.type);
                          }}
                        >
                          <EyeInvisibleOutlined style={{ fontSize: "16px" }} />
                          {translate("hideColumn")}
                        </MenuItem>
                        <MenuItem
                          color="black"
                          onClick={onOpenManageColumnsModal}
                        >
                          {translate("manageColumns")}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Flex>
              </Th>
            )
          );
        })}
      </Tr>
      <Tr bg="white">
        {columns.flatMap((col) => {
          if (verifyIfColumnIsHided(col.type)) {
            return null;
          }

          return col.inputs.map((input, inputIndex) => {
            const label = getInputLabel(input);
            const unitSymbol = input?.unitSymbol;

            const isDateCol = col.type === "date";
            return (
              <Th
                key={`${col.label}-${inputIndex}`}
                color="#006699"
                textAlign="center"
                position={isDateCol ? "sticky" : "relative"}
                left={isDateCol ? 0 : "auto"}
                zIndex={isDateCol ? 100 : "auto"}
                background={isDateCol ? "white" : "inherit"}
                border="1px solid black"
                _after={isDateCol && hasScrolled ? dateColBorderStyle : {}}
                fontSize={"11px"}
                px={2}
              >
                <Text display="flex" flexDirection="column" as="p">
                  <span>{label}</span>
                  {unitSymbol && <span> ({unitSymbol})</span>}
                </Text>
              </Th>
            );
          });
        })}
      </Tr>
      <Tr bg="white">
        {columns.flatMap((col) => {
          if (verifyIfColumnIsHided(col.type)) {
            return null;
          }

          const isDateCol = col.type === "date";

          return col.inputs.map((input, inputIndex) =>
            input.showThresholds ? (
              <Th
                key={`${col.label}-${inputIndex}-input`}
                textAlign="center"
                padding="4px 6px"
                border="1px solid black"
              >
                <Stack flexDir="row" gap={1} alignItems="center" spacing={0}>
                  <Tooltip
                    label={
                      tresholds[input.key!]?.operator === "<"
                        ? translate("tableTresholdTooltipLessThan")
                        : translate("tableTresholdTooltipGreaterThan")
                    }
                    fontSize="sm"
                    hasArrow
                  >
                    <Button
                      w={4}
                      minWidth={6}
                      h={5}
                      padding={0}
                      variant="solid"
                      bg="lightblue"
                      color="#006699"
                      onClick={() => onToggleOperator(input.key!)}
                    >
                      {tresholds[input.key!]?.operator || ">"}
                    </Button>
                  </Tooltip>
                  <Input
                    autoFocus={
                      col.type === "weather" && input.label === "wind_vel"
                    }
                    width="40px"
                    size="xs"
                    title={translate("thresholdTitle") || ""}
                    type="number"
                    onChange={(e) => onTresholdInputChange(e, input.key!)}
                    value={tresholds[input.key!]?.value || ""}
                  />
                </Stack>
              </Th>
            ) : (
              <Th
                key={`${col.label}-${inputIndex}-empty`}
                position={isDateCol ? "sticky" : "relative"}
                left={isDateCol ? 0 : "auto"}
                zIndex={isDateCol ? 2 : "auto"}
                background={isDateCol ? "white" : "inherit"}
                border="1px solid black"
                _after={isDateCol && hasScrolled ? dateColBorderStyle : {}}
              />
            )
          );
        })}
      </Tr>
    </Thead>
  );
}
