import React from "react";
import { Box, Progress } from "@chakra-ui/react";
import { ImageOverlay, MapContainer, Marker, TileLayer } from "react-leaflet";
import "./Styles.css";
import {
  DivIcon,
  Icon,
  IconOptions,
  LatLngBounds,
  LatLngExpression,
} from "leaflet";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { Center, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { IInsightMultiStation } from "services/OceanWeatherIntelligenceService";
import { VisualizationType } from "enum/VisualizationType";
import { ISensorMeasuredData } from "types/ISensorMeasuredData";
import { MapClickHandler } from "./util/MapClickHandler";
import { FlyTo } from "./util/FlyTo";
import { SearchControl } from "./util/SearchControl";

export interface ForecastChartPanelMapProps {
  center?: LatLngExpression;
  zoom?: number;
  tabStateActivty: EnvironmentalType;
  radarData: any[];
  loadingInsightMultiStation: boolean;
  clickMapLatLng: LatLngExpression;
  selectedStation: number;
  iconSelected: Icon<IconOptions> | DivIcon | undefined;
  customMarker: JSX.Element[];
  insightMode: boolean;
  insightMultiStation: IInsightMultiStation | null;
  visualizationType: VisualizationType;
  regionId: number;
  sensorMarkerData: ISensorMeasuredData[];
  mapCenterLatitude: number;
  searchedLatLng: LatLngExpression | null;
  clearSearchedLatLng: () => void;
}

const ForecastChartPanelMap: React.FC<ForecastChartPanelMapProps> = ({
  center,
  zoom,
  tabStateActivty,
  radarData,
  loadingInsightMultiStation,
  clickMapLatLng,
  selectedStation,
  iconSelected,
  customMarker,
  insightMode,
  insightMultiStation,
  visualizationType,
  regionId,
  sensorMarkerData,
  mapCenterLatitude,
  searchedLatLng,
  clearSearchedLatLng,
}: ForecastChartPanelMapProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });

  return (
    <MapContainer
      key={`mapStation-${mapCenterLatitude}`}
      center={center}
      zoom={zoom}
      scrollWheelZoom={true}
      className="map"
      attributionControl={false}
      zoomControl={true}
    >
      <MapClickHandler />
      <FlyTo
        searchedLatLng={searchedLatLng}
        clearSearchedLatLng={clearSearchedLatLng}
      />
      <SearchControl />
      {tabStateActivty === EnvironmentalType.PRECIPITATION &&
        radarData[0]?.map(
          (radar: {
            lat_min: any;
            lat_max: any;
            lon_min: any;
            lon_max: any;
            path: any;
            id: any;
          }) => {
            const { lat_min, lat_max, lon_min, lon_max, path, id } = radar;

            if (!path) {
              return null;
            }

            if (!lat_min || !lat_max || !lon_min || !lon_max) {
              console.error("Coordenadas inválidas para o radar: ", radar);
              return null;
            }

            const bounds = new LatLngBounds(
              [parseFloat(lat_min), parseFloat(lon_min)],
              [parseFloat(lat_max), parseFloat(lon_max)]
            );

            return (
              <ImageOverlay key={id} url={path} bounds={bounds} zIndex={10} />
            );
          }
        )}
      <TileLayer
        attribution='&copy; <a href="https://www.i4sea.com">i4sea</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Center
        hidden={!loadingInsightMultiStation}
        bg="#0000005e"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        top="30%"
        borderRadius="base"
        zIndex={99999}
        py="12px"
        px="16px"
      >
        <Box display="flex" flexDir="column" alignItems="center">
          <Progress
            colorScheme="blue"
            hasStripe={false}
            width="200px"
            size="xs"
            isIndeterminate
          />
          <Text color="white" fontWeight="medium">
            {t("loading")}...
          </Text>
        </Box>
      </Center>

      {clickMapLatLng && selectedStation === 0 && (
        <Marker position={clickMapLatLng} icon={iconSelected}></Marker>
      )}

      <div
        key={`${sensorMarkerData}-${insightMode} - ${insightMultiStation?.id} - ${tabStateActivty} - ${visualizationType} - ${loadingInsightMultiStation} - ${selectedStation}- ${regionId}`}
      >
        {customMarker}
      </div>
    </MapContainer>
  );
};

export default ForecastChartPanelMap;
