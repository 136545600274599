import {
  Table,
  Tbody,
  TableContainer,
  Stack,
  Box,
  Flex,
  useDisclosure,
  Center,
  Text,
} from "@chakra-ui/react";

import { useStores } from "stores/setup/use-store";
import { observer } from "mobx-react";

import { PageTitle } from "components/PageTitle/PageTitle";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";
import { useTranslation } from "react-i18next";
import { BackButton } from "components/BackButton";

import { ManageTabularForecastColumnsModal } from "./components/ManageTabularForecastColumnsModal";
import { SelectStation } from "./components/SelectStation";
import { TableHeader } from "./components/TableHeader";
import { TableRow } from "./components/TableRow";
import { useThreshold } from "./hooks/useThreshold";
import { useHidedColumns } from "./hooks/useHidedCollumns";
import { useForecastTableData } from "./hooks/useForecastTableData";
import { useSelectedStation } from "./hooks/useSelectedStation";
import { StoreState } from "enum/StoreState";
import { ForecastTableLoading } from "./components/ForecastTableLoading";
import { useUserHasScrolled } from "./hooks/useUserHasScrolled";

function ForecastTable() {
  const {
    dataStores: { forecastChartPageStore },
  } = useStores();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    thresholds,
    handleThresholdsInputChange,
    handleThresholdOperatorToggle,
  } = useThreshold();

  const {
    hidedColumns,
    onUpdateHidedColumns,
    isColumnHided,
    onHideEnviromentCollumn,
  } = useHidedColumns();

  const { selectedStation, onChangeSelectedStation } = useSelectedStation();

  const { columnNames, columns, getColorForValue, getType, rows } =
    useForecastTableData(selectedStation);

  const { hasScrolled } = useUserHasScrolled("table-forecast-table");

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastTable",
  });
  const { t: environmentTranslation } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastTable.environmentalVariableEnum",
  });

  const numberOfVisibileColumns = columns.length - hidedColumns.length === 2;

  return (
    <Box mt={4} mb={4} mx={SCREEN_HORIZONTAL_SPACING} overflowX="hidden">
      <Stack>
        <Flex justifyContent="space-between" flex={1}>
          <Center gap={2}>
            <BackButton backRoute="/forecast-charts" />
            <Text color={"newBlue.500"}>{t("title")}</Text>
          </Center>
          <SelectStation
            translate={t}
            stations={forecastChartPageStore.stations}
            selectedStation={selectedStation}
            onChange={onChangeSelectedStation}
            state={forecastChartPageStore.state}
          />
        </Flex>

        {forecastChartPageStore.forecastReportState === StoreState.PEDDING && (
          <ForecastTableLoading translate={t} />
        )}

        {forecastChartPageStore.forecastReportState === StoreState.ERROR && (
          <Center>
            <Text color="black">{t("getDataError")}</Text>
          </Center>
        )}

        {forecastChartPageStore.forecastReportState === StoreState.DONE && (
          <TableContainer
            maxHeight="88vh"
            height="89vh"
            maxWidth="100%"
            overflowX="scroll"
            overflowY="scroll"
            id="table-forecast-table"
          >
            <Table variant="simple" size="sm">
              <TableHeader
                columns={columns}
                tresholds={thresholds}
                verifyIfColumnIsHided={isColumnHided}
                environmentTranslation={environmentTranslation}
                translate={t}
                isHideColumnDisabled={numberOfVisibileColumns}
                onHideColumn={onHideEnviromentCollumn}
                onOpenManageColumnsModal={onOpen}
                onToggleOperator={handleThresholdOperatorToggle}
                onTresholdInputChange={handleThresholdsInputChange}
                hasScrolled={hasScrolled}
              />
              <Tbody>
                {rows.map((row, rowIndex) => {
                  return (
                    <TableRow
                      key={rowIndex}
                      row={row}
                      index={rowIndex}
                      thresholds={thresholds}
                      getColorForValue={getColorForValue}
                      verifyIfColumnHided={isColumnHided}
                      verifyColumnType={getType}
                      hasScrolled={hasScrolled}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>

      <ManageTabularForecastColumnsModal
        isOpen={isOpen}
        onClose={onClose}
        hidedColumns={hidedColumns}
        columns={columnNames}
        translate={environmentTranslation}
        translateForecast={t}
        onUpdateHidedColumns={onUpdateHidedColumns}
      />
    </Box>
  );
}

export default observer(ForecastTable);
